import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <div className="container">
      <div className="content">
        <h2>Ups</h2>
        <p>
          ¡Vaya! Parece que la página a la que has intentado acceder no existe...{' '}
          <Link to="/">¡Sácame de aquí!</Link>
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
